import React from "react"
import { Link } from "gatsby"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Ethereum" />
    <h1>Ethereum Reference</h1>
    <p>A set of reference guides for blockchains of the Ethereum family.</p>

    <p>Our first guide is the <Link to="/cheatsheet/">Solidity &amp; Ethereum cheat sheet</Link>, a side-by-side reference guide for Ethereum programming languges, built to benefit smart contract developers of all levels.</p><br />

    <p>The content is open source and you are welcome to suggest changes at <a href="https://github.com/auditless/ethereum-reference">our GitHub repository</a>.</p><br />

    <h2>Which Ethereum programming language should I use?</h2><br />
    <p>This reference guide covers both Solidity and Vyper and they are viable choices depending on your circumstances (we like both). Solidity came earlier and is
      used more frequently. Its syntax most closely represents imperative languages like C++ and Javascript. Vyper is a newer programming language, which uses
      a simpler and more restrictive design to minimize common smart contract security pitfalls. Vyper uses Python syntax and is still officially at an experimental stage.</p><br />
    
    <h2>Credits</h2><br />
    <p>Solidity syntax rebuilt from <OutboundLink target="_blank" href="https://solidity.readthedocs.io">Solidity reference</OutboundLink></p>
    <p>Vyper syntax rebuilt from <OutboundLink target="_blank" href="https://vyper.readthedocs.io/en/latest/">Vyper reference</OutboundLink></p>
    <p>Inline doctests inspired by <OutboundLink target="_blank" href="https://github.com/raineorshine/solidity-repl">Solidity REPL</OutboundLink></p>
    <p>Cheat sheet inspired by <OutboundLink target="_blank" href="http://hyperpolyglot.org/">Hyperpolyglot</OutboundLink>, our favorite way to learn programming languages.</p>
    <p>Concept and implementation inspired by <OutboundLink target="_blank" href="https://moeamaya.com/">Moe Amaya</OutboundLink>.</p>
    <p>To learn more about Ethereum, see <OutboundLink target="_blank" href="https://ethereum.org/">ethereum.org</OutboundLink></p>
  </Layout>
)

export default IndexPage
